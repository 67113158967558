import React from "react"
import ResourcePost from "../components/ResourcePost";
import MetaHead from "../components/MetaHead";
import Layout from "../components/Layout";
import {graphql} from "gatsby"

const Resources = ({data, pageContext, path}) => {
	const resourcePosts = data.allContentfulResources
	const essentialData = data.contentfulEssentialData
	return (
		<>
		<MetaHead meta={data.contentfulMetaFields} lang={pageContext.langKey} path={path} />
		<Layout essentialData={{...essentialData, ...pageContext, path}}>
			<h1>{essentialData.resourcesTitle}</h1>
			{
				resourcePosts.nodes.map((resource) => {
					return (
						<ResourcePost 
						key={resource.slug}
						uid={resource.slug}
						title={resource.title}
						description={resource.description}
						thumbnail={resource.thumbnail}
						price={resource.price}
						langKey={pageContext.langKey}
						/>
					)
				})
			}
		</Layout>
		</>
	);
}
 
export const query = graphql`
query($langKey: String!){
	allContentfulResources(filter: {node_locale: {eq: $langKey}}) {
		nodes {
			title
			thumbnail{
				fluid{
					...GatsbyContentfulFluid
				}
			}
			description {
				description
			}
			price
			slug
		}
	}
	contentfulEssentialData(node_locale: {eq: $langKey}) {
		workTitle
		resourcesTitle
		footerCopyright
		footerContact
		blogTitle
		aboutTitle
		homeTitle
		imprintTitle
	}
	contentfulMetaFields(identifier: {eq: "resources"}, node_locale: {eq: $langKey}) {
		title
		ogType
		description {
			description
		}
	}
}`

export default Resources;

